header {
	text-align: center;
	padding: 5rem 0 7rem;
	margin-bottom: 5rem;
}

main,
footer,
main > .nq-card {
	width: 100%;
	max-width: 1200px;
	margin: auto;
}

@media (max-width: 1200px) {
	main,
	footer,
	main > .nq-card {
		padding-left: 6px;
		padding-right: 6px;
	}
}

body {
	background-color: var(--nimiq-blue);
	color: #ffffff;
}

input,
select {
	width: 100%;
}

.card-full {
	margin: auto;
	width: 100%;
}

.card-full * {
	max-width: 100%;
}

.center-children {
	text-align: center;
}

.center-children * {
	margin: auto;
}

@media (min-width: 576px) {
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}

#chart-statistics > * {
	padding-top: 2px;
	padding-left: 3.5px;
	padding-right: 3.5px;
	padding-bottom: 2px;
}
